@import '../../../assets/styles/globalStyles.scss';

.list-wrapper-container {
    text-align: left;
    color: $secondary-color;
    padding: 0px 15px;
    height: 100vh;
}

.settings-items {
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;

    .icon-container {
        padding: 0px 10px;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .text {
        margin-left: 10px;
        flex: 1;
        padding: 0;
        margin: 10px 15px;


    }

    .line-separator {
        border-bottom: 1px solid $light-color;
        margin-left: 40px;
        margin-right: 15px;
    }
}

.__list-wrapper-container {
    text-align: left;
    padding: 0 20px;

    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
    }

    .__settings-items {
        background-color: #fdfdfd;
        border-radius: 20px;
        border: 1px solid #d5d5d5;

        .__single_item {
            display: grid;
            grid-template-columns: 1fr 20px;
            align-items: center;
            gap: 15px;
            border-bottom: 1px solid #ddd;
            color: #8a96a3;
            cursor: pointer;
            padding: 15px;
            transition: 0.4s;

            &:hover {
                background-color: #eee2;
                color: #8d8d8d;
            }

            p {
                margin: 0;
                font-size: 15px;
                color: #555555;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}