@import '../../../assets/styles/globalStyles.scss';


.subjects-files-container {
    padding: 30px;
    .subjects-files-content {
        background-color: $white-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        border: 1px solid $tertiary-color;
        color: $secondary-color;
        cursor: pointer;
        &:hover {
            background-color: $secondary-background !important;
        }

    }

    h3{
        word-break: break-all;
    }
}