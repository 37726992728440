@import '../../../assets/styles/globalStyles.scss';

.create-subject-modal {
    min-width: 40vw;

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }

    .file-upload-container {
        padding: 0px 25px;
        font-family: Arial;
        max-height: 450px;
        padding-top: 10px;
    }
}