@import '../../../assets/styles/globalStyles.scss';

.meet_participants{
    padding: 15px;
    ._participants{
        font-weight: 600;
        text-align: left;
        font-size: 16px;
        color: $secondary-color;
        text-decoration: underline;
        margin-bottom: 5px;
    }

    .__card_d {
        padding: 8px 5px;
        transition: 0.4s;
        &:hover {
            background: #ccc2;
        }
        .name{
            width: 90px;
            padding: 0px;
            border-radius: 5px;
            .text{
                font-size: 12px;
                color: $secondary-color;
                font-weight: 600;
            }
        }

    }
}