@import '../../assets/styles/globalColors.scss';

.student_attendence{
    background-color: $white-color;
    border-radius: 15px;
    padding: 15px;
    margin: 0 15px;
    .heading{
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: $secondary-color;
    }
    .wrapper{
        display: flex;
        justify-content: space-between;
    .container{
        text-align: left;
        margin-top: 15px;
        .count{
            font-size: 16px;
            color: $secondary-color;
            font-weight: 600;
            span{
                font-weight: 400;
                font-size: 14px;
                margin-left: 4px;
            }
        }
        .required{
            margin-top: 15px;
            font-size: 12px;
            color: $secondary-color;
        }
    }
}
}