@import '../../assets/styles/globalStyles.scss';

.settings-container{
    height: 100vh;
    text-align: left;
    color: $secondary-color;
    padding:0px 15px
}
.settings-items{
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    .icon-container{
        padding: 0px 10px;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;   
    }
    .text{
        margin-left: 10px;
        flex:1
    }
    .line-separator{
        border-bottom: 1px solid $light-color;
        margin-left: 40px;
        margin-right: 15px;
    }
}
.__settings{
    margin-top: -15px;
}