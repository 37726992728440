@import '../../../assets/styles/globalColors.scss';

.create_poll_wrapper{
    min-width: 50vw;
    height: auto;
    margin: 15px;
    .create_poll_title{
        font-size: 14px;
        font-weight: 600;
        color: $secondary-color;
        margin-bottom: 10px;
    }
    .poll-text-area{
        border: 1px solid $light-color !important;
        all: unset;
        height:80px;
        width:calc(100% - 25px);
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
    }
    input {
        width: 100%;
        padding: 18px 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        font-size: 14px !important;
        font-weight: 400;
        color: #494D5C;
        border: 1px solid #d3cbcba1;
        border-radius: 10px;

        &::placeholder {
            font-size: 14px;
            color: #57575754;
        }

        &:focus {
            border-color: #d3cbcba1;
            outline: none;

        }
    }
    .modal_footer{
        display: flex;
        justify-content: space-between;
        .button_wrapper{
            display: flex;
            justify-content: end;
        }
    }
    .input_container{
        position: relative;
        width: 100%;
        .close-button{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            color: $tertiary-color;
            font-size: 16px;
            cursor: pointer;
        }
    }
}