@import '../../assets/styles/globalStyles.scss';

.line-separator{
    border-bottom: .5px solid $light-color;
}

.subjects-count-name{
    background-color: #ededed;
    border-radius: 30px;
    color: #000;
    font-size: 12px;
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 0px;
    padding: 3px 15px;
    &.subject{
        background-color: #0091EA40;
        color: $secondary-color;
        font-weight: 500;
    }
}