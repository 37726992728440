@import '../../assets/styles/globalColors.scss';
@import '../Login/index.scss';

.classroom_settings_container {
    margin: 0 8px;
    .detail-cover{
        margin-bottom: 15px;
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 18px);
        height: 270px;
        margin-top: -20px;
        span{
            text-align: left;
        }
        .class_logo{
            background-color: $white-color;
            margin: 15px;
        }
    }
    ._classroom_settings {
        background-color: $secondary-background;
        margin: 0 10px;
        border-radius: 15px;

        .wrapper {
            padding: 10px 15px;
            border-bottom: .5px solid $light-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            .value {
                display: -webkit-box;
                -webkit-line-clamp: 3; /* Limit to 3 lines */
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.5rem; /* Define line-height for calculating lines */
              }
              
              .value.expanded {
                -webkit-line-clamp: unset; /* Remove the clamp for expanded text */
                max-height: none;
              }
              
              .read-more {
                color: $secondary-color;
                cursor: pointer;
                margin-top: 8px;
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                &:hover {
                    text-decoration: underline;
                }
              }
              
              .value.collapsed {
                overflow: hidden;
              }
              

            .key {
                color: $tertiary-color;
                padding-bottom: 5px;
            }

            .value {
                color: $secondary-color;
                font-weight: 600;
                font-size: 14px;
                word-break: break-all;
                margin-right: 20px;
            }

            ._board_tag {
                margin: 10px;
                padding: 6px 20px;
                border: .5px solid $light-color;
                border-radius: 20px;
                flex-wrap: wrap;
                width: fit-content;
                display: inline-block;
                color: $secondary-color;
            }
        }
    }

    .title {
        color: $secondary-color;
        font-size: 18px;
        text-align: left;
        margin: 15px 10px;
        font-weight: 600;
    }
}

._classroom_edit_modal {
    min-width: 40vw;
    width: 100%;
    @media (max-width: 600px) {
        min-width: 350px;
    }

    .__modal {
        margin: 30px 20px;
        margin-top: 0;

        .button-wrapper {
            display: flex;
            align-self: flex-end;
            justify-content: end;
        }
        .desc{
            font-size: 12px;
            color: $secondary-color;
        }
        .privacy_option{
            display: grid;
            grid-template-columns: 60px 1fr;
            align-items: center;
            padding: 10px 20px;
            margin: 5px -20px;
            font-size: 18px;
            cursor: pointer;
            position: relative;
            &:hover {
                background-color: $secondary-background;
            }
            i{
                background-color: $secondary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                border-radius: 20px;
                color: $white-color;
                font-size:20px ;
            }
            .icon{
                position: absolute;
                top: 30px;
                left: 52px;
                background-color: $white-color;
                display: flex;
                height: 15px;
                width: 15px;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                color: $primary-color;
                font-size:15px ;
            }
        }
    }
}

.input-group {
    margin-bottom: 5px;
    width: 100%;

    textarea {
        width: calc(100% - 20px);
        height: 100px;
        border: .5px solid $light-color;
        padding: 10px;
        border-radius: 8px;
        outline: 'none';
        border: .5px solid $light-color ;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none;

        &:focus {
            border: .5px solid $primary-color ;
            outline: 'none'
        }

        &::placeholder {
            color: $light-color;
            font-size: 12px;
        }
    }

    label {
        display: block;
        margin-bottom: 8px;
        font-size: 13px;
        color: $secondary-color;
        font-weight: 400;
    }

    input,
    select {
        width: 100%;
        padding: 18px 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        font-size: 14px !important;
        font-weight: 400;
        color: #494D5C;
        border: 1px solid #d3cbcba1;
        border-radius: 10px;

        &::placeholder {
            font-size: 14px;
            color: #57575754;
        }

        &:focus {
            border-color: $primary-color;
            outline: none;

        }

        // appearance: none;
        // -webkit-appearance: none;
    }
}

._classroom_members{
    margin: 20px;
}

.copy_success_span{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary-color; 
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: $white-color; 
    white-space: nowrap;
    margin-left: -30px; 
}