@import '../../../assets/styles/globalStyles.scss';



@media (max-width: 900px) {
  .side-menu-wrapper-hide {
    display: none;
  }
}

.side-menu-wrapper {
  display: flex;
}

.Left-widget {
  text-align: start;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  color: #8a96a3;
  border-radius: 40px;
  font-size: 17px;
  cursor: pointer;
  transition: 0.4s;
  font-weight: 600;
  min-width: 152px;
  margin-top: 5px;
  line-height: 1;

  i {
    font-size: 23px
  }

  &.active {
    // background: $primary-hover-light !important;
    color: #000;
  }

  &:hover {
    background-color: $primary-hover-light !important;
    color: $primary-color;
  }

  .Left-widget-item {
    margin-left: 12px;
    font-weight: 600;

    .count {
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.Left-widget-item {
  cursor: pointer;
}

.__custome_modal {
  min-width: 350px;
  padding: 10px;
  padding-top: 0;

  .__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #575757;

    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }

    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      font-size: 26px;
      padding: 0;
      color: #575757;
      margin-top: 5px;
    }
  }

  .__modal_menu_list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .__menu_single {
      display: flex;
      transition: 0.4s;
      padding: 15px;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      // border-bottom: 1px solid #f1f1f1;
      border-radius: 10px;

      // &:last-child {
      //   border: none;
      //   // &:hover {
      //   //   border-radius: 0px 0px 10px 10px;
      //   // }
      // }

      h1 {
        font-size: 15px;
        margin: 0;
        text-align: left !important;
      }

      p {
        font-size: 12px;
        margin: 0;
        font-weight: 300;
      }


      &:hover {
        background-color: #f9f9f9;
      }

    }
  }
}

.__custom_btn_create {
  padding: 15px 34px;
  padding-left: 17px;
  color: #fff;
  border-radius: 40px;
  border: 0px;
  background: $primary-color;
  width: 70%;
  margin: 20px 0;
  cursor: pointer;
  transition: 0.4s;
  font-weight: 500;
  line-height: 1;
  display: flex;
  gap: 9px;
  margin-left: 20px;
  font-size: 17px;

  i {
    position: relative;
    top: 2px;
    margin-right: 1px;
    font-size: 14px;
  }

  &:hover {
    background: $primary-hover;
  }
}

.__drawer__ {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;

  .drawer-header {
    height: 60px;
    border-bottom: 1px solid #70707024;
    display: flex;
    // grid-template-columns: 40px 1fr 10px;
    align-items: center;
    text-align: left;
    padding: 0px 20px;
    gap: 10px;
    margin-right: -1px;
    flex-shrink: 0;

    .__settings{
      display: flex;
      flex:1;
      flex-shrink: 0;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 0px;
    }


    // .class-name-wrapper {
    //  display: flex;
    //  align-items: center;
    //  gap: 10px;
    // }

    .__wrap {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      justify-content: flex-start;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-radius: 10px;
      max-width: 56%;

      @media (min-width: 1370px) {
        max-width: 80%;
      }

      @media (max-width: 1070px) {
        max-width: 50%;
      }

      @media (max-width: 980px) {
        max-width: 45%;
      }

      .__wrap_text {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;

        @media (min-width: 1370px) {
          max-width: 80%;
        }

        @media (max-width: 1070px) {
          max-width: 60%;
        }

        @media (max-width: 980px) {
          max-width: 55%;
        }
      }
    }
  }
}


.sidemenu-content-wrapper {
  padding: 0px 20px;
  height: calc(100vh - 100px);
  padding-top: 20px;
}

.sidemenu-content-wrap {
  padding: 10px 30px;
  height: calc(100vh - 30px);
}

.sidemenu-footer {
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  right: 0;
  left: 0;
  background-color: $white-color;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #eee;

  .sidemenu-profile-card {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;

    p {
      margin: 0;
      font-size: 11px;
      color: #575757;
    }

    label {
      background: rgb(0 145 234 / 25%) 0% 0% no-repeat padding-box !important;
      color: $primary-color;
      padding: 1px 11px;
      border-radius: 30px;
      font-size: 13px;
      font-weight: 800;
    }

    button {
      border: none;
      all: unset;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.sidemenu-profile-card-content {
  display: flex;

  h1 {
    font-size: 14px;
    margin: 0;
    display: flex;

  }

  .___label {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

#basic-menu {
  .menu-item {
    font-size: 14px;
    font-family: "Manrope";
    padding: 10px 15px;
    font-weight: 500;
  }
}


@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.__classroom_drwr_list {
  max-height: 50vh;
  overflow-y: auto;

  .__single_class_drwr {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    padding: 10px;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
      background: #ccc2;
    }



    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 13px;
    }

    span {
      color: #666;
      font-size: 10px;
    }

    .requested-class {
      color: #fff;
      background-color: #ffa031;
      font-size: 9px;
      border-radius: 20px;
      padding: 2px 10px;
    }

    .active-class {
      color: #fff;
      background-color: $primary-color;
      font-size: 9px;
      border-radius: 20px;
      padding: 2px 10px;
    }
  }
}

.__join_class {
  padding: 20px;
  background-color: #f7f7f7;
  border-top: 1px solid #cccccc52;
  overflow: hidden;
  display: grid;
  grid-gap: 10px;

  &.last {
    border-radius: 0px 0px 10px 10px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.4s;
    color: #000;
  }
}

.modal-container-classroom {
  position: absolute;
  top: 63px;
  left: 15px;
  // transform: translate(-50%, -50%);
  width: 18vw;
  background-color: #ffffff;
  /* replace with your desired background color */
  box-shadow: 1px 1px 10px #ddd;
  padding: 0;
  border-radius: 14px;
  transition: all 0.2s ease-in;
  animation: slide-down 0.2s ease-in;
  overflow: hidden;
  outline: none;

  @media (max-width: 900px) {
    width: 262px;
  }
}

.__letter {
  background: #ffffff;
  color: #999;
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 16px;
  border: 1px solid #ccc;
}

h2 {
  i {
    color: $tertiary-color;
    margin-left: 5px;
  }
}