@import '/src/assets/styles/globalColors.scss';

.comment-list-wrapper{
    .count{
        width: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
    }
    .footer{
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 600;
        color:$primary-color;
        cursor: pointer;
    }
    .comment-list{
        margin-bottom: 10px;
        border-radius: 20px;
        // border: 1px solid $light-color;
        background-color: $secondary-background;
        padding: 10px;
        width: max-content;
        max-width: 97%;
        .comment-container{
            height: fit-content;
            margin-left: 45px;
            width: fit-content;
    }
    }
    .progress{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.no-comment-text{
    text-align: center;
    margin: 50px 0;
    font-size: 12px;
    color: $secondary-color;
}