@import '/src/assets/styles/globalColors.scss';

._create_class_name{
    display: flex;
    margin: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    .title{
        color: $tertiary-color;
        font-size: 16px;
    }
    .__sub_title{
        color: $secondary-color;
        font-weight: 600;
        font-size: 28px;
    }
    .MuiOutlinedInput-root {
        border-radius: 10px !important;

        & fieldset {
            border-width: 1.5px;
            border-color: #B4B4B4; 
        }

        &:hover fieldset {
            border-color: #B4B4B4; 
        }

        &.Mui-focused fieldset {
            border-color: $primary-color; 
        }

        // &.Mui-error fieldset {
        //     border-color: $orange-button-color 
        // }

        .MuiOutlinedInput-input {
            height: 20px;
            font-size: 13px !important;
            color: $secondary-color
        }
        // & + .MuiFormHelperText-root {
        //     font-size: 12px; 
        //     color: #6C6C6C; 
        //     margin-top: 4px; 
    
        //     &.Mui-error {
        //         color: $orange-button-color; 
        //     }
        // }
    }
    .__footer_text{
        font-size: 14px;
        font-weight: 500;
        color: $normal-color;
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
    }
    .button_container{
        display: flex;
        width: 100%;
        justify-content: end;
    }
    .input_container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap:20px
    }
}