@import '../../assets/styles/globalStyles.scss';

.meeting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -30px;
    padding: 20px;
    background-color: #f4f4f4;
    height: calc(100vh - 120px);
    position: relative;
  
  
  .meeting-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 80%;
    max-width: 1200px;
    margin-top: 20px;
    height: 100%;
    margin-bottom: 100px; /* Space for fixed controls */
  }
  .name{
    color:$secondary-color;
    font-weight: 600;
    display: flex;
    margin-top: auto;
  }
  
  .speaker, .participant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    //box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .speaker{
    border: 1px solid $primary-color;
  }

  .profile_container{
    flex:1;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .profile-image {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 12px;
    color: gray;
  }
  
  .controls-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 350px;
    max-width: 600px;
  }
  
  .mute-btn, .video-btn, .record-btn, .options-btn, .disconnect-btn {
    font-size: 24px;
    color: #007bff;
    background: transparent;
    border: none;
  }
  
  .mute-btn:hover, .video-btn:hover, .record-btn:hover, .options-btn:hover, .disconnect-btn:hover {
    color: #0056b3;
  }
  
  .mute-btn, .video-btn, .record-btn, .options-btn, .disconnect-btn {
    cursor: pointer;
  }
  
  .disconnect-btn {
    color: #ff4d4f;
  }
  
  .disconnect-btn:hover {
    color: #d93636;
  }
  

  @media (max-width: 1200px) {
    .meeting-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }
  }
 
  
  @media (max-width: 768px) {
    .meeting-grid {
      grid-template-columns: 1fr; /* 1 column on small screens */
    }
  }

  @media (max-width: 900px) {
    .controls {
      margin-right: 25%; /* 2 columns on medium screens */
    }
  }

  @media (max-width: 600px) {
    .controls {
      margin-right: 0%; /* 2 columns on medium screens */
    }
  }
}
  