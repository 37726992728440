@import '../../../assets/styles/globalStyles.scss';

.event-wrapper {
  padding: 10px;
  text-align: left;

  .event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondary-background;
    margin: 10px 0px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 10px;

    .context_wrapper{
      margin-top: -12px;
      margin-bottom: -12px;
    }

    .name {
      flex: 1;
      margin: 0px 10px;
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* Number of lines to show */
      -webkit-box-orient: vertical;
      margin: 2px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time{
      margin-top: 4px;
    }
    }
  }
}

.loader_content_container{
  position: absolute;
  right: 15px;
  top:90px
}

.event__custome_modal {
  min-width: 300px;
  padding: 15px;
  width: 40vw;

  .__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #575757;

    h4 {
      margin: 0;
      font-size: 13px;
      font-weight: 800;
    }

    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      font-size: 26px;
      padding: 0;
      color: #575757;
    }
  }

  .__menu_single {
    grid-template-columns: 30px 1fr;
    border-radius: 16px;
    transition: 0.4s;
    padding: 7px 0px;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    .__desc{
      max-height: calc(90vh - 450px);
      overflow-y: auto;
    }
  }

  .timing-container {
    background-color: $secondary-background;
    margin: 5px 0px;
    padding: 5px 15px;
    border-radius: 10px;

    .timing-text {
      background-color: $white-color;
      width: fit-content;
      padding: 4px 10px;
      border-radius: 10px;
      border: 1px solid lightgray;
      font-size: 14px;
    }
  }
}