@import '../../../assets/styles/globalColors.scss';

.poll_card{
    border: 1px solid $light-color;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: -10px;
    border-radius: 10px;
    .poll_title{
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: $secondary-color;
        margin-bottom: 5px
    }
    .poll_option_container{
        display: flex;
        gap: 5px;
        padding: 5px 0;
        cursor: pointer;
        margin: 5px 0;
        .poll_option_wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 5px;
        .poll_option{
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items:flex-start;
            .poll_option_content{
                display: flex;
                .voter_extra_count{
                    background-color: $tertiary-color;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    color: $white-color;
                    font-weight: 600;
                    margin-right: -2px;
                }
                .voter_count{
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    color: $secondary-color;
                    font-weight: 600;
                }
                .voter_img_container{
                    display: flex;
                    align-items:flex-start;
                    justify-content: center;
                    img{
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        object-fit:cover;
                        padding: 0;
                        margin-right: -5px;
                    }
                }
            }
        }
    }
    }
}