@import '../../../assets/styles/globalStyles.scss';

.no-note-container{
    gap:15px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px !important;
    @media (min-width: 600px) {
        height: 80vh;
    }
    img{
        width: 40px;
        object-fit: contain;
    }
}