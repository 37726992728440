
@import '../../../assets/styles/globalStyles.scss';

.class-switch-wrapper{
    padding: 15px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap:10px;
    min-width: 250px;
    border-radius:10px ;
}
.class-switch-text{
    color: $secondary-color;
    font-weight: 600;
}

.class-switch-wrapper-container{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300 !important;
    background-color:$white-color;
    border: .5px solid #000;
    height: 200px;
    border-radius: 10px;
    all:unset
}

.create-class-switch-modal{

    min-width: 300px;
    height: 160px;
    max-width: 400px;
    padding: 15px;
    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        color: #575757;
        height: 30px;

        h4 {
            margin: 0;
            font-size: 13px;
            font-weight: 800;
        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }
    .class-switch-container{
        gap:2;
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 15px;
    }
}