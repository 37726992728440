@import '../../../assets/styles/globalColors.scss';

.photo-picker-img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .photo-picker-orginal-img {
        height: 250px;
        align-self: center;
        display: flex;
    }
}

// .photo-picker-preview {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//     gap: 10px;

//     li {
//         display: flex;
//         margin-bottom: 5px;
//         cursor: pointer;
//         &:hover {
//             background-color:$secondary-background
//         }

//         .file-preview {
//             width: 25px;
//             height: 40px;
//             padding-left: 10px;
//             overflow: hidden;
//             margin-right: 10px;

//             img {
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//             }
//         }
//     }
//     .file-details {
//         flex-grow: 1;
//         padding-right: 10px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         .file-name {
//             flex-grow: 1;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             margin-right: 10px;
//             max-width: 280px;
//         }

//         .delete-button {
//             padding: 5px;
//             background-color: #dc3545;
//             color: white;
//             border: none;
//             border-radius: 4px;
//             cursor: pointer;

//             svg {
//                 vertical-align: middle;
//             }
//         }
//     }
//     .selected{
//         background-color: $secondary-background;
//     }
// }
.photo-picker-button-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: space-between;

    button {
        height: 30px;
        border-radius: 10px !important;
    }

    .crop-button {
        cursor: pointer;
        border-radius: 10px;
        color: $secondary-color;
        border: 1px solid $secondary-color;
        font-weight: 600;
        width: 65px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        background-color: $secondary-background;
    }
}

.create-subject-modal {
    min-width: 45vw;
    

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }
}


.create-photo-picker-modal {
    min-width: 40vw;

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }

    .__oute_body_wrap {
        padding: 10px 20px;
    }

}

.photo-picker-component {
    max-height: 60vh;
    overflow-y: auto;
}



.photo-picker-preview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    grid-row-gap: 10px;
    align-items: center;

    li {
        display: grid;
        grid-template-columns: 40px 1fr 20px;
        cursor: pointer;
        gap: 10px;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #eee;
        padding: 0 2px;

        &:hover {
            background-color: $secondary-background
        }

        .file-preview {
            width: 100%;
            height: 40px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover !important;
                border-radius: 8px;
                object-position: top;
            }
        }
    }

    .file-details {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .file-name {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // margin-right: 10px;
            // max-width: 280px;
        }

        .delete-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .selected {
        background-color: $secondary-background;
    }
}

.__btn_crt{
    padding-top: 0;
}