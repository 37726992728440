@import '../../../assets/styles/globalStyles.scss';

.create-post-modal {
    min-width: 40vw;

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }

    .__oute_body_wrap{
        padding: 10px 20px;
    }

}
.post-text-area{
    border: none;
    all: unset;
    height:80px
}
.__buttons2{
    display:flex;
    align-items:center;
    justify-content:end;
    gap:10px;
    font-size:18px;
    padding-bottom: 10px;
    button{
        min-width: 100px;
        &:disabled{
            background-color: #ccc !important;
        }
    }
}

.__link_container__{
    display:flex;
    align-items:center;
    justify-content:end;
    gap:10px;
    font-size:18px;
    button{
        min-width: 100px;
        &:disabled{
            background-color: #ccc !important;
        }
    }
}

.___errror{
    font-size: 12px;
    margin: 0;
    margin-left: 10px;
    color: red;
}