
@import '../../../assets/styles/globalStyles.scss';
.curriculum-files-wrapper{
    padding: 15px;
    text-align: left;
    background-color: $secondary-background;
    border-radius: 15px;
    .title-note-container{
        margin-top: 0px;
    }
}