.create-comment-modal{
    padding: 15px;
.__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
    color: #575757;
    height: 25px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;

    h4 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;

    }

    button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        font-size: 26px;
        padding: 0;
        color: #575757;
    }
}
.comment-wrapper{
.comment-container{
    height: 60vh;
    width: 70vw;
    max-width: 800px;
    overflow-y: auto;
    position: relative;
}
}
}