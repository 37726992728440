@import '../../../assets/styles/globalStyles.scss';

.search-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .search-input {
    width: 100%;
    padding: 14px 12px;
    border: .5px solid #B4B4B4;
    border-radius: 10px;
    font-size: 16px !important;
    &::placeholder {
        color: $tertiary-color;
        font-size: 16px;
      }
  }
  
  .search-input:focus {
    outline: none !important;
    border-color: #B4B4B4;
    font-size: 16px;
  }