@import './globalColors.scss';


body {
    font-family: "Manrope", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
}

h1 {
    margin: 0;
    padding: 0;
    font-family: "Manrope", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
label,
textarea,
input,
button {
    font-family: "Manrope", sans-serif !important;
    font-weight: 400;

    input {
        font-size: .7rem !important;
    }
}

.submit-button {
    padding: .8rem !important;
    border: none;
    height: 42px;
    background-color: $primary-color !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 20px !important;
    box-shadow: none !important;

    &:hover {
        background-color: $primary-hover !important;
        box-shadow: none !important;
    }

    &.disable_style {
        background-color: $light-color !important;
        color: $white-color !important;
        cursor: not-allowed !important;
    }
}

.submit-button {
    padding: .8rem !important;
    border: none;
    height: 42px;
    background-color: $primary-color !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 20px !important;
    box-shadow: none !important;

    &:hover {
        background-color: $primary-hover !important;
        box-shadow: none !important;
    }

    &.Mui-disabled {
        background-color: $light-color !important;
        color: $white-color !important;
        cursor: not-allowed !important;
    }
}

.outlined-button {
    padding: .8rem !important;
    border: none;
    height: 40px;
    background-color: $white-color !important;
    color: $primary-color !important;
    cursor: pointer !important;
    box-shadow: 0 !important;
    border-radius: 5px !important;

    &:hover {
        background-color: $secondary-color !important;
    }
}

.secondry-button {
    padding: 0.8rem 1.3rem !important;
    height: 40px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f9f9f9 !important;
    color: #494D5C !important;
    cursor: pointer !important;
    border-radius: 20px !important;
    text-transform: capitalize !important;

    // box-shadow:0 !important;
    // &:hover {
    //     background-color: 'inherit'; // Keeps the background color unchanged
    //     box-shadow: 'none';
    // }

    span {
        color: $secondary-color;
        font-weight: bold;
    }
}

.create-button {
    padding: 0px 30px !important;
    height: 46px;
    // border: 1px solid $light-color;
    color: $secondary-color !important;
    cursor: pointer !important;
    border-radius: 23px !important;

    .text {
        font-family: Manrope;
        font-weight: 500;
        font-size: 14px;
    }
}

.home-form-title {
    text-align: left;
}

.custom-link {
    color: $secondary-color;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid;
    cursor: pointer;
}

.notes-container {
    display: flex;
    text-align: left;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    padding: 0px 15px !important;

    &.selected {
        background: $secondary-background;
    }

    &:hover {
        background-color: $secondary-background;
    }

    &.fullscreen {
        &:hover {
            background-color: $white-color;
        }
    }
}

.notes-sub-container {
    display: flex;
}

.notes-sub-name {
    background-color: #0091EA40;
    padding: 3px 20px;
    border-radius: 15px;
    margin-bottom: 10px;
    color: $secondary-color;
    font-weight: 500;
    font-size: 10px !important;
    margin-right: 10px;
    white-space: nowrap; // Prevents the text from wrapping to the next line
    overflow: hidden; // Hides the overflowing content
    text-overflow: ellipsis;
}

.notes-count-name {
    background-color: #ededed;
    padding: 0px 15px;
    border-radius: 15px;
    color: #000000;
    font-weight: 400;
    font-size: 10px;
    min-width: 38px;
    margin-bottom: 10px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.notes-user-card-wrapper {
    margin-bottom: 5px;
}

.dropdown-menu {
    @media (min-width: 600px) {
        display: none;
    }
}

.hide-dropdown-menu {
    display: none;
}

.note-files-wrapper {
    @media (max-width: 600px) {
        border: 1px solid $light-color;
        border-radius: 10px;
    }

    .create-button-wrapper {
        padding: 15px;
        padding-top: 0;
    }

    &.fullscreen {
        border: none;

        // @media (min-width: 600px) {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        // }
        .wrapper {
            border: 1px solid $light-color;
            border-radius: 10px;
            margin: 5px;
        }
    }
}

.page-header {
    height: 60px;
    border-bottom: 1px solid #70707024;
    // border-left: 1px solid #70707024;

    text-align: left;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;

    .option-container {
        flex-direction: row;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .notification_icon {
            // background-color: #575757;
            display: flex;
            align-items: center;
            // height: 34px;
            // width: 34px;
            border-radius: 17px;
            justify-content: center;
            cursor: pointer;

            .icon {
                font-size: 30px;
                align-self: center;
                color: $secondary-color;
            }
        }
    }
}

.burger-menu {
    @media (min-width: 901px) {
        display: none;
    }

    margin-right: 10px;
    display: flex;
}

.modal-footer-button {
    margin: 20px 25px;
    display: flex;
    justify-content: end;
}

.___label h1 {
    font-weight: 700;
}

.class-room-list-card {
    height: 30px;
    min-width: 80px;
    font-size: 10px;
    font-family: Manrope;
    border-color: $primary-color;
    color: $primary-color;

    span {
        font-weight: 800 !important;
    }
}


.__cust {
    font-weight: 700 !important;
    text-transform: uppercase;

    div {
        font-weight: 700 !important;
        padding: 0 15px;
        text-transform: uppercase;
    }
}

.subject-tab {
    background-color: #0091EA40;
    padding: 3px 20px;
    border-radius: 15px;
    color: $secondary-color;
    font-weight: 500;
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: min-content;
    margin-top: 10px;
}

.feed-subject-title {
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    white-space: pre-line;
}


.__filter {
    background: #f7f7f7;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
}

.___menu UL {
    padding: 0;
}

.__menu_com {
    padding: 0 !important;
}

.__phon_ip {
    font-size: 15px !important;
}


.title-note-container {
    h4 {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}

.offline_banner {
    background-color: $secondary-color;
    color: white;
    text-align: center;
    overflow: hidden;
    font-weight: bold;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 12px;
        color: $white-color;
        margin: 0;
    }
}

.right-grid {
    @media (max-width: 600px) {
        display: none;
    }
}
.MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23) !important;
 }
 .__select {
    border-radius: 20px !important;
    min-width: 150px !important;
    font-size: 14px !important;
    max-width: 220px;
}

.modal_container_notification {
    position: absolute;
    top: 63px;
    right: 60px;
    // transform: translate(-50%, -50%);
    width: 24vw;
    background-color: #ffffff;
    /* replace with your desired background color */
    box-shadow: 1px 1px 10px #ddd;
    padding: 0;
    border-radius: 10px;
    transition: all 0.2s ease-in;
    animation: slide-down 0.2s ease-in;
    overflow: hidden;
    outline: none;

    @media (max-width: 900px) {
        width: 262px;
    }

    .__notification_drwr_list {
        height: calc(100vh - 78px);
        // overflow-y: auto;
    }
}