@import '../../assets/styles/globalStyles.scss';

.user-single{
    height: 100px;
    width: 100px;
    border-radius: 140px;
    color: $secondary-color;
    object-fit: cover;
}
.profile-pic-container{
    height: 100px;
    width: 100px;
    border-radius: 140px;
    background-color: $secondary-background;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-card{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    margin-bottom: 30px;
    font-weight: 300;
    .name {
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 18px;
    }
}

.img-edit-button{
    position: 'relative' !important;
    background-color:$secondary-color !important; 
    left: 30px; 
    top: 100px;
    height:20px;
    width:20px;
    color: $white-color !important;
    font-size: 12px;
    &:hover{
        background-color: $secondary-color;
    }
    .camera{
        height: .8rem;
    }
}

.modal-modal-title{
    min-width: 300px;
    text-align: center;
}

.__delete-account{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: 1px solid #f44336;
    border-radius: 20px;
    margin: 0px 20px;
    padding: 15px;
    color: #f44336;
    cursor: pointer;
    background-color: #fdfdfd;
    text-align: left;
    margin-top: 15px;
    transition: 0.4s;
    p {
        margin: 0;
        font-size: 16px;
    }
    &:hover{
        background-color: #00000005;
    }
}