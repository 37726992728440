@import '../../assets/styles/globalColors.scss';

.__set_privacy_container {
    margin: 0px 15px;
    margin-bottom: 20vh;

    h1 {
        color: $secondary-color;
        font-size: 16px;
        font-weight: 600;
    }

    .__body {
        color: $secondary-color;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
    }

    .__footer {
        margin-top: 20px;
        display: flex;
        justify-content: end;
    }

    .radio_container {
        .__form {
            width: 100%;

            .__box {
                border: 1px solid $secondary-color;
                border-radius: 15px;
                padding: 15px;
                background-color: $secondary-background;
                flex: 1;
                display: flex;
                cursor: pointer;
                align-items: flex-start;

                &.selected {
                    opacity: .6;
                    border: 1px solid #f1f1f1;
                }

                .box_content {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    .__allow_update_container {}

    .__image_picker {
        .__image_picker_container {
            display: flex;
            gap: 5px;
            align-items: center;
        }

        .__image_picker_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
            border: 1px dashed $secondary-color;
            background-color: $secondary-background;
            padding: 15px;
            border-radius: 15px;

            .__image_picker_content {
                display: flex;
                flex-direction: column;
                width: 90%;
                @media (max-width: 600px) {
                    width: 68%;
                }

                .title {
                    color: $secondary-color;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .__sub__body {
                    color: $secondary-color;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                    margin-top: 5px;
                }
            }

            .__icon {
                padding: 8px 10px;
                border: 1px solid $tertiary-color;
                border-radius: 8px;
            }
        }
    }
}