@import '../../../assets/styles/globalColors.scss';

.__custom__image_picker {
    img {
        height: 80px;
        width: 80px;
        object-fit: contain;
        border-radius: 10px;
    }

    .label_container {
        align-items: center;
        justify-content: center;
        gap: 10px;
        display: flex;
    }
}

.__custom__image_picker__modal {
    position: relative;
    background-color: $white-color;
    height: 50vh;
    width: 50vw;
    display: flex;
    align-self: center;
    @media (max-width: 600px) {
        width: 80vw;
        height: 40vh;
    }
}

.__custom__image_picker__modal__footer {
    display: flex;
    margin: 30px 0px;
    justify-content: center;
    align-items: center;

    ._slider_label {
        margin-right: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $secondary-color;

        &:last-of-type {
            margin-left: 20px;
        }
    }
}

.__custom__image_picker__footer {
    display: flex;
    align-items: end;
    justify-content: end;
}
.label_container{
.__icon_pic{
}
label{
    width: 100% !important;
}
}