@import '../../../assets/styles/globalStyles.scss';

.note-files-container{
    padding: 15px 20px;
    display: flex;
    justify-content:space-between;
    text-align: left;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    .note-files-sub-container{
        align-items: center;
        display: flex;
        .text-container{
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            .note-files-name{
                font-size: .9rem;
                font-weight: 500;
                color:#333;    
                margin-top: -2px;
                white-space: pre-line;
            }
            .time{
                font-size: .7rem;
                font-weight: 600;
                color: $normal-color;
            }
        }
    }
    &:hover {
        background-color: $secondary-background;
      }
      .icon-Container{
        display: flex;
        align-items: center;
      }
}

.note-files-items{
    &.fullscreen{
        border: none;
        @media (min-width: 600px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .wrapper{
            border: 1px solid $light-color;
            border-radius: 10px;
            margin: 5px;
        }
    }
}

.note-files-wrapper{
    .create-button-wrapper{
        margin-top: 20px;
    }
}
