
@import '../../../assets/styles/globalStyles.scss';

.create-events-modal {
    min-width: 40vw;
    @media (max-width: 600px) {
        min-width: 350px;
    }
    max-height: 85vh;
    overflow-y: auto;
    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }

    .file-upload-container {
        padding: 0px 25px;
        font-family: Arial;
        overflow-y: auto;
        min-height: 445px;

        .input-group {
            margin-bottom: 5px;

            textarea {
                width: calc(100% - 20px);
                height: 100px;
                border: .5px solid $light-color;
                padding: 10px;
                border-radius: 8px;
                outline: 'none';
                border: .5px solid $light-color ;
                overflow: auto;
                outline: none;

                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;

                resize: none;

                &:focus {
                    border: .5px solid $primary-color ;
                    outline: 'none'
                }

                &::placeholder {
                    color: $light-color;
                    font-size: 12px;
                }
            }

        }
    }
}