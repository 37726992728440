@import '../../assets/styles/globalStyles.scss';

.home {
    display: flex;
    min-height: 10vh;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 20vh;
    padding: 0px 15px;
    gap: 10px !important;
    padding: 0 15px;

    .header-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 20px;

        .join-class-text {
            min-width: 150px;
            font-weight: 500;
        }

        .btn_container {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: end;
            @media screen and (max-width: 568px) {
                flex-direction: column;
                
            }
        }
    }
    .no_data{
        margin-top: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $tertiary-color;
        width: 100%;
    }
}


.find-classroom {
    background-color: #fff !important;
    color: #4d4d4d !important;
    border: 1px solid #ddd !important;
    font-weight: 700 !important;
    padding: 0.8rem !important;
    height: 42px;
    cursor: pointer !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    &:hover {
        background-color: #f5f5f5 !important;
    }
}