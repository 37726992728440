@import '../../assets/styles/globalStyles.scss';

.content-wrapper {
    word-wrap: break-word;
    margin: 15px 0;
    white-space: break-spaces;
}

.delete-confirmation {
    min-width: 350px;
    max-width: 400px;
    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }
    .button-wrapper{
        display: flex;
        gap:5px;
        padding:15px;
        justify-content: flex-end;
    }
    .report-item{
        padding: 10px;
        margin: 5px 20px; 
        border: 1px solid $light-color;
        border-radius: 10px;
        font-weight: 600;
        cursor: pointer;
        // &:hover {
        //     background-color: $secondary-background;
        // }
        &.selected {
            background-color: $secondary-background;
        }
    }
    .delete-text{
        font-weight: 600;
    }
    .report-text{
        padding: 15px;
        text-align: justify;
        font-weight: 500;
        font-size: 14px;
    }
    .error-text{
        color: red;
        margin-left: 20px;
    }
}

.__like{
    cursor: pointer;
}


.__event_sec {
    display: flex;
    gap: 30px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.__sing_ev_sc div {
    padding: 3px 10px;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-top: 4px;
    background: #fff;
}

.no_post{
    .no_post_text{
        font-size: 16px;
        margin: 10px 0;
        color: $tertiary-color;
    }
}
.feed_container{
    padding-left: 45px;
}
.feed_loader{
    margin-top: 20px;
}

.home-feed-title{
    text-align: left;
    margin-left: 20px !important;
}
