.notifications_wrapper{
    // display: flex;
    justify-content: center;
}

.__tabs{
    padding: 0 20px;
    margin-top: 10px;
    min-height: 30px !important;
    button{
        min-height: 30px;
        text-align: left;
        display: flex;
        align-items: self-start;
        padding: 0;
        min-width: 25px;
        margin-right: 10px;
    }
    .notifications_title{
        text-align: left;
        margin-left: 20px;
    }
}