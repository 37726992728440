@import '../../../assets/styles/globalColors.scss';

.url_content_text_wrapper {
    margin-bottom: 16px;
    .content-wrapper {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-bottom: 0;
        max-height: calc(20px * 4 + 10px);
        transition: max-height 0.5s ease;

        &.expanded {
            max-height: 10000px;
            -webkit-line-clamp: unset;
        }
    }
    .read-more {
        color: $tertiary-color;
        cursor: pointer;
        display: block;
        font-size: 12px;
    }
    .link{
        color: $primary-color;
    }
}