@import '../../../assets/styles/globalStyles.scss';

.todays-classes-card-container {
    width: 100%;
    display: flex;
    // overflow-x: auto;
    white-space: nowrap;
    margin: 0 auto;
}

.todays-classes-card {
    min-width: 150px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px !important;
    gap: 10px;
    margin-bottom: 20px !important;
    border: 1px solid #eee;
    background-color: #f8f8f8;
}

.todays-classes-content {
    text-align: left;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    .join_button{
        background-color: $primary-color;
        align-self: flex-end;
        display: flex;
        margin-left: auto;
        padding: 2px 10px;
        border-radius: 10px;
        color: $white-color;
        font-weight: 600;
        cursor: pointer;
    }
}

.todays-classes-text {
    overflow: hidden;        // Hide overflowing text
    white-space: nowrap;     // Prevent wrapping
    text-overflow: ellipsis; // Apply ellipsis
    flex: 1;                 // Allow the text element to take up available space
  }

.todays-classes-index {
    background-color: $white-color;
    height: 20px;
    width: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 5px;
    min-width: 20px;
    font-size: 11px;
    font-weight: 800;
}

.todays-classes-time{
    min-width: 100px;
    padding: 3px 10px;
    border-radius: 15px;
    border: 1px solid #DDDDDD;
    font-weight: 400 !important;
    font-size: 12px !important;
    background: #fff;
    padding-top: 4px;
}

.todays-classes-empty-card{
    border-radius: 15px;
    padding: 5px 10px;
}
.todays-classes-empty-card_teacher{
    border-radius: 15px;
    padding: 5px 10px;
    width: fit-content;
    cursor: pointer;
}

.todays-classes-time-container{
    display: flex;
    justify-content:space-between;
    align-items: center;
}