@import '/src//assets/styles/globalColors.scss';

.post-doc-wrapper {
    margin-bottom: 10px;
    width: 100%;

    .post-doc-container {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $light-color;
        padding: 10px 10px;
        border-radius: 10px;
        cursor: pointer;

    .text-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 90%;

        .text {
            width:calc(100%) ;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            color: $secondary-color;
        }
        .size-text{
            color: $secondary-color;
            font-size: 10px;
        }
    }
    }


}