@import '../../assets/styles/globalStyles.scss';

.__change_name{
    .MuiOutlinedInput-root {
        border-radius: 10px !important;

        & fieldset {
            border-width: 1.5px;
            border-color: #B4B4B4; // Default border color
        }

        &:hover fieldset {
            border-color: #B4B4B4; // Border color on hover
        }

        &.Mui-focused fieldset {
            border-color: $primary-color; // Border color when focused
        }

        .MuiOutlinedInput-input {
            height: 20px;
            font-size: 13px !important;
            color: $secondary-color
        }
    }
}