.scroll-to-top {
    position: sticky;
    bottom: 10px;
    z-index: 1000;
    height: 25px;
    width: fit-content;
    align-self: center;
    display: flex;
    left: 50%;
    @media (max-width: 600px) {
       left: 36%;
      }

    .scroll-to-top-button {
        background-color: #777; 
        color:#fff; 
        opacity: .5;
        border: none;
        padding: 4px 15px;
        border-radius: 15px;
        cursor: pointer;
        font-size: 11px;
        // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        transition:  0.3s;

        &:hover {
            opacity: 1;
            // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); 
        }
    }
}
