@import '../../assets/styles/globalStyles.scss';

.line-separator {
    border-bottom: .5px solid #efefef;
    margin: 0px -15px;
}

.notes-container-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.title-note-container {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    margin-top: 15px;

    .note-files-name {
        font-size: 1rem;
        font-weight: 700;
        color: #333;
        margin-top: 0px !important;
        word-wrap: break-word;
        overflow-wrap: anywhere;
        white-space: pre-line;
    }
}

.create-button-wrapper {
    display: flex;
    // padding: 0px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
}

.loader-container {
    margin-top: 20px;
}

.find-classroom-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $tertiary-color;
    margin-bottom: 5px;
    padding-top: 10px;
    justify-content: flex-start;
    text-align: left;

    .home-form-title {
        color: #494D5C;
    }
}

.notes-count-name-text {
    border-radius: 15px;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    min-width: 38px;
    height: 22px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document_detail_loader{
    margin-bottom: 20vh;
    min-height: 100vh;
}
.document_detail_wrapper{
    margin-bottom: 20vh;
}