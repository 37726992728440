@import '/src/assets/styles/globalColors.scss';

.__template_content_container {
    height: calc(100% - 40px);
    display: flex;
    width: 100%;
    position: relative;
    .template_container {
        min-height: calc(100% - 60px);
        margin-bottom: 10vh;
        max-height: calc(100% - 60px);
        overflow-y: auto;
        margin-bottom: 100vh !important;
        width: 100%;

        .__template {
            display: flex;
            margin: 0 20px;
            flex-direction: column;
            align-items: flex-start;
            .no_template{
                font-size: 16px;
                font-weight: 400;
                color: $tertiary-color;
                width: 100%;
                text-align: center;
                margin-top: 20px;
            }

            .progress {
                width: 100%;
            }

            .title {
                color: $tertiary-color;
                font-size: 16px;
            }

            .__sub_title {
                color: $secondary-color;
                font-weight: 600;
                font-size: 28px;
                display: flex;
                align-items: center;
                gap: 8px
            }

            .MuiOutlinedInput-root {
                border-radius: 10px !important;

                & fieldset {
                    border-width: 1.5px;
                    border-color: #B4B4B4; // Default border color
                }

                &:hover fieldset {
                    border-color: #B4B4B4; // Border color on hover
                }

                &.Mui-focused fieldset {
                    border-color: $primary-color; // Border color when focused
                }

                .MuiOutlinedInput-input {
                    height: 20px;
                    font-size: 13px !important;
                    color: $secondary-color
                }
            }

            .__footer_text {
                font-size: 14px;
                font-weight: 500;
                color: $normal-color;
                text-align: right;
                width: 100%;
                margin-bottom: 20px;
            }

            .button_container {
                display: flex;
                width: 100%;
                justify-content: end;
            }

            .input_container {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                gap: 20px
            }

            .__board_template {
                border-bottom: 1px solid $light-color !important;
                cursor: pointer;
                display: flex;
                padding: 10px 0;
                width: 100%;

                .__board_logo {
                    height: 40px;
                    width: 40px;
                    padding: 0px;
                    margin-right: 10px;
                    border-radius: 20px;
                }

                .__board_container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;

                    .__board {
                        display: flex;
                        font-size: 14px;
                        color: $secondary-color;
                        font-weight: 500;
                    }

                    .description_tags {
                        text-align: left;
                        margin: 0;
                        font-size: 12px;
                        font-weight: 400;
                        color: $normal-color;
                    }
                }
            }

            .__board_university {
                display: flex;
                font-size: 14px;
                color: $secondary-color;
                font-weight: 500;
                border-bottom: 1px solid $light-color !important;
                cursor: pointer;
                padding: 10px 0;
                width: 100%;
            }

            .skip__header {
                display: flex;
                width: 100%;
                padding: 0;
                justify-content: space-between;
                align-items: center;

                .skip {
                    color: $secondary-color;
                    font-weight: 500;
                    font-size: 14px;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .__filters {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    .template__footer_container {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
}