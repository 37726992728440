@import '../../assets/styles/globalStyles.scss';

.curriculum-wrapper{
    margin-top: 15px;
    text-align: left;
    padding: 0 20px;
    .title{
        background-color: $secondary-background;
        margin: 10px 0px;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        transition: 0.4s;
        &:hover {
            background: rgb(0 145 234 / 10%) 0% 0% no-repeat padding-box !important;
            transition: 0.4s;
        }
        &.selected {
             background: rgb(0 145 234 / 25%) 0% 0% no-repeat padding-box !important;
            color: #0091EA;
          }
    }


.loader-container{
    align-self: center;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
}

.create-button-wrapper{
    padding: 20px;
    padding-top: 0;
}

.curriculum_button_wrapper{
    margin-top: 15px;
}