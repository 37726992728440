@import '../../../assets/styles/globalStyles.scss';

.create-logout-modal {
    min-width: 40vw;

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }

    .__logout-content {
        padding: 10px 20px;
        p {
            font-size: 17px;
            margin: 0;
        }
    }

    .___btn{button{
        background: #eee !important;
        color: red !important;
        transition: 0.4s;
        &:hover{
            background: #ddd !important;
        }
    }}
}