@import '../../assets/styles/globalColors.scss';

.add-classmates {
    margin: 0 15px;

    .class-count {
        font-size: 17px;
        text-align: left;
        font-weight: 600;
        color: #494D5C;
        margin-top: 10px;
        margin-bottom: 15px;
        width: 100%;
    }

    .add_classmates_list {
        background-color: $secondary-background;
        border-radius: 10px;
        .add_classmates_item {
            display: grid;
            grid-template-columns: 40px 1fr 40px;
            border-bottom: .5px solid $light-color;
            padding: 10px;
            align-items: center;

            .name {
                display: flex;
                align-items: center;
                font-weight: 700;
                width: 100%;
                color: $secondary-color;
            }
            .desc{
                color: $tertiary-color;
                font-size: 12px;
                text-align: left;
            }
        }
    }
    .add_selected_list{
        display: flex;
        align-items: center;
        margin-top: 15px;
        height: 60px;
        overflow-x: auto;
        .add_selected_item{
            margin-right: 10px;
            position: relative;
            i{
                color: $primary-color;
                position: absolute;
                top:-5px;
                right: -5px;
                cursor: pointer;
            }
        }
    }
    .options_container{
        display: flex;
        gap: 15px;
    .option{
        background-color: $secondary-background;
        border-radius: 10px;
        height: 30px;
        color: $secondary-color;
        font-size: 12px;
        gap:5px;
        display: flex;
        padding: 4px 10px;
        cursor: pointer;
        align-items: center;
    }
}
}
.add_classmates__header{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copy_success_span{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary-color; 
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: $white-color; 
    white-space: nowrap;
    margin-left: -30px; 
}