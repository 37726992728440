$primary-color: #00B2F8;
$secondary-color: #494D5C;
$tertiary-color: #ABABAB;
$normal-color : #707070;
$light-color : #DDDDDD;
$white-color : #ffffff;
$error-color : rgba(255, 0, 0, .65);
$modal-color : #F7F8F9;
$secondary-background: #F8F8F8;
$orange-button-color:#ffa031;
$primary-hover:#00a5e5;
$primary-hover-light:#00b2f80a;
