@import '../../assets/styles/globalStyles.scss';

.line-separator{
    border-bottom: .5px solid $light-color;
}

.note-files-name{
    font-size: 1rem;
    font-weight: 700;
    color:#333333;
    word-wrap: break-word;    
    overflow-wrap: anywhere;
    margin-top: 15px;
    white-space: pre-line;
}

.document_container{
    margin-bottom: 20vh;
}

