@import '../../../assets/styles/globalStyles.scss';

.class-room-detail-cover {
    width: 100%;
    height: 180px;
    justify-content:left;
    align-items:end;
    display: flex;
    background-size:cover ;
    background-position:center ;

  }
  .class_logo{
        height: 50px;
        width: 50px;
        padding: 15px;
        border-radius: 20px;
}

.classroom-detail-modal{
    @media (max-width: 901px) {
        width: 320px;
    }
    width: 500px;
}

.detail-cover-text{
    font-weight: 600;
    &.id{
        font-weight: 100;
    }
}
.detail-cover-wrapper{
display: flex;
flex-direction: column;
margin-bottom: 15px;
height: 50px;
color:$white-color ;
}

.tab-button{
    display: flex;
    background-color:$white-color;
    justify-content: right;
    // padding-right: 15px;
    // padding-bottom: 15px;
}
