
.App {
  text-align: center;
}

/* Custom scroll container */

/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: .5em; /* Set the width of the scrollbar */
  height: 2px; /* Set the height of the scrollbar */
  border-radius: 7px; /* Rounded corners */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 7px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background-color: #00000029; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Optional border to make the thumb appear inset */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c2c2c2; /* Darker color on hover */
}

@import "~@flaticon/flaticon-uicons/css/all/all";