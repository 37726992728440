@import '/src/assets/styles/globalColors.scss';
.post-url-wrapper {
    display: flex;
    flex-direction: column;
    .url-preview{
        cursor: pointer;
        padding: 10px 20px;
        border: 1px solid $light-color;
        border-radius: 10px;
        margin-bottom: 10px;
        a{
            color: $primary-color;
        }
        .url{
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }
    }
}