@import '../../../assets/styles/globalColors.scss';
.post-text-area-wrapper {
    width: 100%;
    max-height: 70px;
    overflow-y: auto;

    textarea {
        border: none;  // No border for input
        outline: none; // Remove outline for input
      }
}

.mentions__mention {
    background-color: white; position: relative;
    z-index: 1;
    color: $primary-color !important;
    pointer-events: none;
    position: relative;
    top: -1px;
    line-height: 24px;
  }


  .___Ment__suggestions__list{
    transition: 0.4s;
  }

  .___Ment__suggestions__item{
    transition: 0.4s;
    &:hover{
        background-color: #eee;
    }
  }
.___Ment__suggestions__item--focused{
    background-color: #eee;
}

.___Ment__input{
  line-height: 23px;
}