@import '../../../assets/styles/globalColors.scss';

.__full_list {
    .__header_list{
        display: flex;
        margin-right: 10px;
    .class-count {
        font-size: 17px;
        text-align: left;
        font-weight: 600;
        color: #494D5C;
        margin-top: 10px;
        margin-bottom: 15px;
        width: 100%;
    }
}
    .__admin {
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 5px 10px;
        text-transform: uppercase;
        background-color: #fff;
    }


    .__card_d {
        // border-bottom: 1px solid #ddd;
        padding: 10px;
        transition: 0.4s;

        // &:first-child {
        //     border-top: 1px solid #ddd;
        // }

        &:hover {
            background: #ccc2;
        }
        .__role{
            background-color: $secondary-background;
            width: 90px;
            padding: 5px;
            border-radius: 5px;
            font-size: 12px;
            color: $secondary-color;
            font-weight: 600;
        }

    }
}
.classmates_list__popover{
    min-width: 300px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .popover_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #ededed;
        padding: 10px 0;
        .name{
            font-weight: 700;
            width: 100%;
            padding: 10px 0;
            text-align:left;
            color: $secondary-color;
            margin-left: 20px;
        }
    }
    .item_list{
        width: calc(100% - 40px);
        margin-top: 10px;
    .items{
        background-color: $secondary-background;
        width: 100%;
        cursor: pointer;
        padding: 10px 0;
        border-radius: 10px;
        margin: 10px 0;
        text-align: center;
        color: $secondary-color;
        font-weight: 600;
        opacity: .8;
        &:hover{
            opacity: 1;
        }
    }
}
}