@import '../../../assets/styles/globalStyles.scss';

.card-container {
  width: 100%;
  display: grid;
  white-space: nowrap;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  }
  
  .quick-access-card {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    cursor: pointer;
    border: 1px solid #00b2f838;
    border-radius: 13px;
    padding-left: 20px !important;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    &:hover{
      background-color: $secondary-background;
      transition: 0.4s;
      // &:after{
      //   width: 7px;
      //   background: #ff000033;
      //   height: 100%;
      //   position: absolute;
      //   content: '';
      //   right: 0;    
      //   top: 0;
      //   transition: 0.4s;
      // }
    }
  }
  
  .quick-access-icon {
    width: 35px;
    height: 35px; 
    object-fit: contain; 
  }
  .quick-access-img-container{
    width: 100px;
    display: flex;
    align-items: center;
    gap: 7px;
    i{
      color: $primary-color;
    }
  }
  
  .card-content {
    text-align:left;
    width: 100px;
  }
  
  p {
    font-weight: bold;
    margin-bottom: 5px;
  }
  