@import 'assets/styles/globalColors.scss';

.url-input-wrapper {
    margin-top: 15px;

    .url-input-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;

        button {
            height: 44px;
        }

        .button-text {
            min-width: 100px;
        }

        .MuiOutlinedInput-root {
            border-radius: 10px !important;
            height: 44px;
        }
    }

    .list-container {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 10px;


        a {
            font-size: 14px;
            text-decoration: underline;
            color: $primary-color !important;
            font-weight: 600;
            max-width: 50vw;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }
}


.__list_urls {
    padding-inline-start: 20px;
}