.comment-input {
    display: flex;
    align-items:flex-start;
    padding: 8px 10px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    background-color: #f0f2f5;
    margin: 0 auto;
    position: relative;
    margin-top: 10px;
    position:relative;
    bottom: 15px;
    left: 0px;

  
    .profile-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  
    .comment-input-field {
      flex: 1;
      padding: 8px 12px;
      border: none;
      border-radius: 16px;
      margin-left: 10px;
      background-color: #fff;
      outline: none;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      resize: none;
      margin-right: 30px;
    }
  
    .send-button {
      background: none;
      border: none;
      margin-left: 10px;
      cursor: pointer;
      padding: 0;
      display: flex;
  
      svg {
        fill: #0084ff;
      }
  
      &:hover svg {
        fill: #006bbf; /* Darker blue on hover */
      }
    }
    .send-button-wrapper{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position:absolute;
      right: 10px;
      bottom: 11px;
    }
  }
  .comment_error_message{
    color: red;
  }
  