@import '../../../assets/styles/globalColors.scss';

.post_photo_scroller {
    display: flex;
    .carousel-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
        width: 150px;
        .img{
            height: 200px;
            width: 150px;
            border-radius: 10px;
            object-fit: cover;
            cursor: pointer;
            background-color: #000;
        }
    }

    .crop-button {
        padding: 7px 12px;
        background-color: #007bff;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, .2));
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        position: absolute;
        border-radius: 5px;
        left: 5px;
        top:5px;
        .button_wrapper{
            justify-content: space-between;
            display: flex;
            .icon-large{
                font-size: 20px;
            }
        }
        &.right{
            right: 5px;
            left:auto
        };
    }

    .crop-button:hover {
        background-color: #0056b3;
    }
}


.post_photo_scroller {
    position: relative;
    display: flex;
    align-items: center;
}

.carousel-container {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
}

.carousel-item {
    min-width: 150px;
    // margin: 0 10px;
    position: relative;
}

.scroll-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    z-index: 10;
    background: #00000078;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    color: #fff;
    transition: 0.4s;
    &:hover{
        background: #000000cc;
    }
}

.scroll-button.left {
    position: absolute;
    left: 0;
}

.scroll-button.right {
    position: absolute;
    right: 0;
}