@import '../../assets/styles/globalStyles.scss';

.sign-in-sign-up {
    display: flex;
    width: 100%;
    height: 100vh;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        overflow-y: auto;
    }
}

.image-container,
.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-container {
    flex: 1
}

.image-container {
    background-color: $primary-color !important;
}

.image-container {
    background-color: #f0f0f0;
}

.form-container {
    background-color: #fff;
}

.hide-eye-icon {
    cursor: pointer;
    margin-top: 8px;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-container label {
    font-weight: bold;
}

@media (min-width: 768px) {
    .image-container {
        flex: 1;
    }
}

.signup-form-wrapper {
    flex-direction: column;
    display: flex;
    padding: 10px;
    align-self: center;
}

.sign-up-container .login-form {
    padding: 10px;
}

.from-profile {
    height: 300px !important;
    background-color: red !important;
    padding: 10px;
}

.login-form {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-direction: column;
    border: 0;
    margin-top: 15px;
    min-width: 300px !important;
    // margin: 10px !important;

    .MuiOutlinedInput-root {
        border-radius: 10px !important;

        & fieldset {
            border-width: 1.5px;
            border-color: #B4B4B4; // Default border color
        }

        &:hover fieldset {
            border-color: #B4B4B4; // Border color on hover
        }

        &.Mui-focused fieldset {
            border-color: $primary-color; // Border color when focused
        }

        .MuiOutlinedInput-input {
            height: 20px;
            font-size: 15px !important;
            color: $secondary-color;
            font-weight: 600;

        }
    }
}

.input-field {
    margin: 5px;
    font-size: .7rem !important;
}

.Login-pic {
    height: auto;
    width: -webkit-fill-available;
}

.Login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    width: -webkit-fill-available;
}


.Login-heading {
    padding-top: 50px;
    height: 100px;
}

.login-sub-wrapper {
    background-color: $primary-color;
    flex: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #E5EFF3;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;

    @media (max-width: 768px) {
        display: none;
    }

    .text {
        text-align: left;
        width: 100%;
        padding-left: 150px;
        padding-top: 50px;
        font-size: clamp(1rem, 1.72vw, 3vw)
    }
}

.login-img {
    height: 100vh;
    max-width: 33vw;
    object-fit: contain;
    margin: 0 auto;
    width: 100%;
}

.first-name {
    color: $white-color;
    font-size: 4rem;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-top: -10px;
    }
}

.second-name {
    color: $secondary-color;
}

.login-toogle-form {
    background-color: $white-color !important;
    color: $primary-color !important;

    &:hover {
        background-color: $secondary-color !important;
        color: $white-color !important;
    }
}

.flip-card {
    background-color: transparent;
    width: 350px;
    height: 600px;
    perspective: 1000px;
}

.phone-number-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.phone-form-back-button {
    align-self: flex-start;
    height: 25px;
    width: 20px;
    color: #989898;
    margin-bottom: 20px;
    cursor: pointer;
}

.phone-number-form-container {
    display: flex;
    justify-items: left;
    flex-direction: column;
    padding: 20px;
    min-width: 300px;
}

.phone-number-form-title {
    text-align: left;
}

.phone-number-form-container.button {
    background-color: red !important;
}

.containerClass {
    background-color: red !important;
}

.login-alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &.bottom {
        top: auto;
        bottom: 0;
    }
}

.MuiBox-root.css-wpwytb {
    align-self: center;
}

.flip-card-front {
    @media (max-width: 768px) {
        flex: 1;
    }

    flex: .7;
    display: flex;
    justify-content: center;

    .login-container {
        max-width: 400px;
        display: flex;
    }

    .login-classteam-logo {
        width: 75px;
        align-self: center;
        object-fit: contain;
        margin-bottom: 20px;
    }
    .login-university-logo {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        align-self: center;
        object-fit: contain;
        margin-bottom: 20px;
        background-color: $white-color;
    }
}

.login-signup-text {
    display: flex;
    justify-content: center;
    gap: 2;
    margin-top: 20px;

    .signup-text {
        text-decoration: underline;
        color: #000;
        margin-left: 5px;
        margin-top: 2px;
        font-size: 13px;
        cursor: pointer;
    }
}

.login-signup-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .or-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        margin: 20px 0;
        position: relative;

        &::before,
        &::after {
            content: '';
            flex: 1;
            height: 1px;
            background-color: $tertiary-color;
        }

        &::before {
            margin-right: 0px;
        }

        &::after {
            margin-left: 0px;
        }

        .or-text {
            padding: 0 10px;
            font-size: 14px;
            color: $tertiary-color;
            font-weight: 600;
            background-color: #fff;
            /* Ensure the text background matches the page */
            z-index: 1;
            /* Ensure the text is above the lines */
        }
    }

        .phone-button {
            border-radius: 10px;
            width: 100%;
            font-size: 15px;
            font-weight: 700;
            border: 1.5px solid #B4B4B4;
            color: #494D5C;
            font-family: "Manrope" !important;
            height: 45px;
        }
        }

.goback-button {
    position: absolute;
    left: 0;
    top: 0;
}

.otp-resend-text {
    display: flex;
    gap: 2;
    margin-top: 20px;

    .signup-text {
        text-decoration: underline;
        color: #000;
        margin-left: 5px;
        margin-top: 2px;
        font-size: 13px;
    }
}

.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
    border-radius: 10px 0px 0px 10px;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 10px 0px 0px 10px !important;
}

.forget-send-otp{
    align-self: flex-end;
    margin-top: 20px;
}
.phone-error-msg{
    text-align: right;
    color: red;
    margin-top: -8px;
    font-size: 10px;
}

.otp-error-msg{
    text-align: left;
    color: red;
    margin-top: 5px;
    font-size: 10px;
}

.create_account_title{
    margin-bottom: 20px;
}

.otp_input_button{
    justify-content: flex-end;
    display: flex;
    margin-top: 25px;
}

.reset_password_button{
    margin-top: 25px;
    margin-bottom: 15px;
    gap:10px
}
.reset_password_secondry_button{
    min-width: 230px;
    font-weight: 600;
}
.signup_form_button{
    margin-top: 25px;
}
