@import '../../../assets/styles/globalColors.scss';

.switch_footer{
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    margin-bottom: 15px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    .switch_footer_wrapper{
        .name{
            font-size: 18px;
            color: $secondary-color;
            text-align: left;
            font-weight: 700;
         }
         .status{
            font-size: 12px;
            color: $tertiary-color;
            text-align: left;
         }
    }
}