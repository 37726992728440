.__activities_list {
    padding: 20px;
    .__single_activity {
        text-align: left;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        padding-bottom: 10px;
        &:hover{
            background-color: #eee2;
        }
        h4{
            margin: 0;
            font-weight: 500;
        }
        p{
            margin: 0;
            color: #777;
            font-size: 10px;
        }
    }
}