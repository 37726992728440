@import '../../../assets/styles/globalStyles.scss';

.create-note-modal {
    min-width: 40vw;

    @media (max-width: 600px) {
        min-width: 350px;
    }

    width: 100%;
    background-color: $white-color;
    border-radius: 20px;

    .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
        color: #575757;
        height: 25px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;

        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            font-size: 26px;
            padding: 0;
            color: #575757;
        }
    }
}

.file-upload-container {
    padding: 0px 25px;
    font-family: Arial;
    max-height: 450px;
    padding-top: 15px;

    .input-group {
        margin-bottom: 5px;

        textarea {
            width: calc(100% - 20px);
            height: 100px;
            border: .5px solid $light-color;
            padding: 10px;
            border-radius: 8px;
            outline: 'none';
            border: .5px solid $light-color ;
            overflow: auto;
            outline: none;

            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            resize: none;

            &:focus {
                border: .5px solid $primary-color ;
                outline: 'none'
            }

            &::placeholder {
                color: $light-color;
                font-size: 12px;
            }
        }

        label {
            display: block;
            margin-bottom: 8px;
            font-size: 13px;
            color: $secondary-color;
            font-weight: 400;
        }

        input,
        select {
            width: 100%;
            padding: 18px 15px;
            box-sizing: border-box;
            margin-bottom: 10px;
            font-size: 14px !important;
            font-weight: 400;
            color: #494D5C;
            border: 1px solid #d3cbcba1;
            border-radius: 10px;

            &::placeholder {
                font-size: 14px;
                color: #57575754;
            }

            &:focus {
                border-color: $primary-color;
                outline: none;

            }

            // appearance: none;
            // -webkit-appearance: none;
        }
    }

    .file-upload-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .file-upload-button {
            padding: 8px 17px;
            background-color: $secondary-color;
            color: white;
            font-size: 13px;
            font-weight: 600;
            border-radius: 32px;
            cursor: pointer;
            position: relative;

            input {
                display: none;
            }
        }
    }

    .file-list {
        max-height: 150px;
        list-style-type: none;
        overflow-y: auto;
        padding: 0;

        li {
            padding: 10px;
            display: flex;
            align-items: center;
            border: 1px solid #eee;
            border-radius: 10px;
            height: 16px;
            margin-bottom: 7px;

            .file-preview {
                width: 25px;
                overflow: hidden;
                margin-right: 10px;
                height: 25px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .file-details {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .file-name {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 10px;
                    max-width: 280px;
                    font-size: 13px;
                }

                .delete-button {
                    padding: 5px;
                    background-color: #dc3545;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;

                    svg {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}