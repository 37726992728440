@import '../../assets/styles/globalColors.scss';

.__recent{
    padding: 20px !important;
    min-height: 50vh !important;
    overflow-y: auto;
    max-height: 87vh;
    margin-bottom: 0 !important;
    transition: 0.4s;


    // &:hover::-webkit-scrollbar {
    //     width: 8px; /* Width of the scrollbar */
    //     transition: 0.4s;
    //   }
      
    //  &:hover::-webkit-scrollbar-thumb {
    //     background-color: #888; /* Color of the scrollbar thumb */
    //     border-radius: 10px; /* Roundness of the scrollbar thumb */
    //   }
      
    //  &:hover::-webkit-scrollbar-thumb:hover {
    //     background-color: #555; /* Color of the scrollbar thumb when hovered */
    //   }
      
    //  &::-webkit-scrollbar {
    //     width: 0; /* Hide scrollbar by default */
    //     transition: 0.4s;
    //   }

    .__title{
        word-wrap: break-word;
        word-break: break-word;
        cursor: pointer;
    }
    .__list{
        background-color: $secondary-background;
        border-radius: 10px;
    }
    .seprator{
        height: 10vh;
    }


}
.__common_head{
    text-align: center;
    color: #575757;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}
.__no_notification{
    text-align: center;
    color: $tertiary-color;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center
}