
@import '../../../assets/styles/globalStyles.scss';
.date-picker-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    .date-picker {
        display: flex;
        gap: 15px;
        width: 100%;
    }

    .css-33be37-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 8px;
        font-size: 12px;
        color: $normal-color;
        svg{
            color: $normal-color;
        }
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        border:.5px solid $light-color;
    }

    .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
        border: .5px solid $primary-color;
    }

    .MuiOutlinedInput-root {
        &:hover fieldset {
            border:1px solid  $light-color;
        }
    }
}

.date-picker-title {
    font-size: 12px;
    font-weight: 400;
}

.event-picker__label{
    font-size: 13px;
    color: #494D5C;
}