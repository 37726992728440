@import '../../assets/styles/globalColors.scss';
.__activities_lists {
    padding: 20px;
    padding-top: 0;

    .__single_activity {
        text-align: left;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:hover {
            background-color: #eee2;
        }

        h4 {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
        }

        p{
            font-weight: 500;
            // font-size: 14px;
        }

        .__created_at {
            margin: 0;
            color: #777;
            font-size: 10px;
        }
    }
}

.my_activities__view_more{
    color: $tertiary-color;
    font-size: 12px;
    font-weight: 500;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}