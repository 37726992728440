@import '../ClassRoomDetails/index.scss';

.academic-details-card{
    display: flex;
    justify-content: space-between;
}

.academic-key-text{
    font-size: 14px;
    color: $secondary-color;
    margin-bottom: 4px;
}
.academic-value-text{
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $secondary-color;
}