@import '../../../assets/styles/globalStyles.scss';

.classroom-details{
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(80vh - 280px);
    border-radius: 10px;
    border: 1px solid #eee;
}
.classroom-details-wrapper{
    padding: 10px;
}
.classroom-details-card{
    display: flex;
    flex-direction: column;
}
.line-separator{
    border-bottom: 1px solid $light-color;
    padding-top: 10px;
}
.key-text{
    font-size: 12px;
    color: $tertiary-color;
    margin-bottom: 4px;
}
.value-text{
    font-size: 16px;
    display: flex;
    align-items: center;
}
