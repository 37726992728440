@import '../../assets/styles/globalColors.scss';

.qrcode__wrapper {
    height: 100%;

    .qrcode {
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .class_logo {
            background-color: $secondary-background;
            margin-bottom: -15px;
            margin-top: 20px;
        }

        .classteam_logo {
            height: 70px;
            width: 55px;
            margin-top: 40px;
        }

        .qrcode__container {
            background-color: $secondary-background;
            border-radius: 10px;
            padding: 0 50px;
            padding-bottom: 50px;

            .qrcode__title {
                color: $secondary-color;
                font-weight: 600;
                margin-top: 10px;
            }

            .qrcode__id {
                color: $tertiary-color;
                margin: 4px 0;
            }
        }

        .qrcode__footer {
            display: flex;
            font-size: 24px;
            color: $primary-color;
            font-weight: 600;

            div {
                font-weight: 600;
                color: $secondary-color;
            }
        }
    }

    .share__container {
        display: flex;
        cursor: pointer;
        gap: 8px;
        justify-content: center;
        align-items: center;
        i{
            color: $secondary-color;
            font-size:18px;
            margin-bottom: -5px;
        }
        .share {
            font-size: 18px;
            color: $secondary-color;
            text-decoration: underline;
        }
    }
}