@import '../../../assets/styles/globalStyles.scss';
.custom-menu {
    .MuiIconButton-root {
      padding: 0;
    }
  
    .menu-icon {
      margin-right: 8px;
    }
  }
  #long-menu{
    .item-container{
        color: #575757;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Manrope';

    }
  }
  