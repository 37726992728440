@import '../../../assets/styles/globalColors.scss';

.poll_preview_card{
    border: 1px solid $primary-color;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: -10px;
    border-radius: 10px;
    .poll_title{
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: $secondary-color;
        margin-bottom: 5px
    }
.poll_Preview_header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .icon_container{
        gap: 10px;
        font-size: 18px;
        display: flex;
    }
}
.poll_preview_option_card{
    border: 1px solid $tertiary-color;
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 10px;
}
}