.__report_block {
    text-align: left;
}

.___footer-button {
    margin-top: 25px;
}

.file-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-gap: 10px;
}

.file_object {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;

    img {
        height: 100px;
        object-fit: cover;
        width: 100%;
    }

    span {
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: #000;
        color: #fff;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input{
        display: none;
    }

}

.___footer-button{
    button{
        &:disabled{
            background-color: #ccc !important;
        }
    }
}