@import '../../assets/styles/globalColors.scss';

.__user_profile_container{
    .user_profile{
        display: flex;
        justify-content: center;
    }
    .user_role{
        display: flex;
        justify-content: center;
        margin-top: 5px;
    .__role{
        background-color: $secondary-background;
        width: 90px;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        color: $secondary-color;
        font-weight: 600;
    }
}
    .user_name{
        margin-top: 5px;
        color: $secondary-color;
        font-weight: 700;
        font-size: 16px;
    }
    .user_stats{
        display: flex;
        margin: 20px 40px;
        align-items: center;
        justify-content: space-around;
        .divider{
            height: 40px;
            width: 1px;
            background-color: $secondary-color;
        }
        .title{
            color: $tertiary-color;
            font-size: 14px;
        }
        .value{
            color: $secondary-color;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .user_common_classrooms{
        text-align: left;
        margin: 0 20px;
        font-size: 16px;
        font-weight: 600;
        color: $secondary-color;
    }
}

.no_user_found{
    font-size: 16px;
    font-weight: 400;
    color: $tertiary-color;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}