@import '../../../assets/styles/globalStyles.scss';

.class-room-card {
    align-items: center;
    border: 1px solid #70707024;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    margin: 5px 0;
    padding: 20px;
    transition: .4s;

    &:hover {
        background-color: #ccc2 !important;
    }

    &.list {
        margin: 0;
        border: 0;
        border-bottom: .5px solid $light-color;
        border-radius: 0;
        height: 40px;
        cursor: pointer;
    }

    .class_logo {
        height: 60px;
        width: 60px;
        padding: 0;
        border-radius: 10px;
        object-fit: cover;

        &.small {
            height: 30px;
            width: 30px;
        }
    }

    .join-class-room-button {
        border-radius: 30px;
        background-color: $primary-color;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        padding: 6px 15px;
        text-transform: capitalize;
        height: 33px;
        transition: 0.4s;
        min-width: 100px;

        &:hover {
            background-color: #0486d5;
        }

        span {
            font-weight: 500 !important;
        }

        &.joined {
            background-color: $primary-color;
            color: $white-color;
        }
        &.pending {
            background-color: $orange-button-color;
            color: $white-color;
            border-color:$orange-button-color ;
        }
        &.request,
        &.join{
            background-color: $secondary-background;
            color: $secondary-color;
            border: 1px solid $light-color;
        }
    }
    .view-class-room-button {
        border-radius: 30px;
        background-color: none;
        color: $secondary-color;
        font-size: 13px;
        font-weight: 500;
        padding: 6px 15px;
        text-transform: capitalize;
        height: 33px;
        transition: 0.4s;
        min-width: 100px;
        border-color: $tertiary-color;

        &:hover {
            background-color: #fff;
            border-color: $tertiary-color;
        }

        span {
            font-weight: 500 !important;
        }

        &.joined {
            background-color: $primary-color;
            color: $white-color;
        }
        &.pending {
            background-color: $orange-button-color;
            color: $white-color;
            border-color:$orange-button-color ;
        }
        &.request,
        &.join{
            background-color: $secondary-background;
            color: $secondary-color;
            border: 1px solid $light-color;
        }
    }
}

.class-card {
    width: 100%;
    display: flex;
    flex-direction: column;

    .class-count {
        font-size: 17px;
        text-align: left;
        font-weight: 600;
        color: #494D5C;
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

.class-room-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: start;
    justify-content: space-around;
    height: 100%;
    margin-left: 10px;
    color: $secondary-color;

    .name {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        text-align: left;

        &.list {
            font-size: 14px;
            font-weight: 600;
        }
        i{
            color:$tertiary-color;
            margin-left: 5px;
          }
    }

    .student {
        font-size: 12px;
    }

    .class-roomId {
        font-size: 13px;
        font-weight: 500;
        text-align: left;

        &.list {
            font-size: 12px;
            font-weight: 400;
            color: $tertiary-color;
        }
    }
}

.class-room-student {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #494D5C;
}

.class-room-content {
    .active-class {
        color: #fff;
    background-color: $primary-color;
    font-size: 9px;
    margin-left: 10px;
    border-radius: 20px;
    padding: 2px 10px;
    }
}

.class-room-content {
    .requested-class {
        // color: #fff;
        // background-color: $orange-button-color;
        color: #fff;
    background-color: #ffa031;
    font-size: 9px;
    margin-left: 10px;
    border-radius: 20px;
    padding: 2px 10px;
    }
}

.student {
    font-weight: 600;
}