@import '../../assets/styles/globalStyles.scss';


.timetable-container {
    padding: 20px;

    @media (max-width: 550px) {
        padding: 10px;
    }
    .no_data{
        margin-top: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $tertiary-color;
        width: 100%;
    }
}

.timetable-paper {
    box-shadow: none !important;
    border: 1px solid #eee;
    overflow: hidden;
    border-radius: 15px !important;
    .day_text{
        font-weight: 700;
        font-size: 14px !important;
    }
}

.timetable-header {
    padding: 10px;
    background-color: #f5f5f5;
    text-align: center;
    border-right: 1px solid #eee;

    @media (max-width: 550px) {
        padding: 4px;

        h6 {
            font-size: 12px !important;
        }
    }
}

.timetable-row {
    border-top: 1px solid #e0e0e0;
    flex-wrap: nowrap !important;
}

.timetable-timing {
    background-color: #fafafa;
    padding: 10px;
    text-align: center;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 550px) {
        padding: 4px;
    }
}

.timetable-cell {
    padding: 10px;
    text-align: center;
    border-right: 1px solid #eee;
    cursor: pointer;

    @media (max-width: 550px) {
        padding: 4px;
    }
}

.subject-display {
    padding: 10px;
    background-color: #e0e0e0;
    cursor: pointer;
}

.subject-option {
    padding: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    margin-top: 5px;
}

.subject-option:hover {
    background-color: #d0d0d0;
}

.timetable-header.current-day {
    background-color: #00b2f80d;
}

// .timetable-row.current-time {
//     background-color: #ffe0b2;
// }

.timetable-cell.current-day-cell {
    background-color: #00b2f80d;
}


.single-select-container {
    display: inline-block;
    margin-top: 15px;
    padding: 0 20px;

    @media (max-width: 550px) {
        padding: 0 10px;
    }
}

.single-select-item {
    padding: 9px 30px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 55px;
    cursor: pointer;
    transition: 0.4s;
    text-align: center;
    width: max-content;
    float: left;
    margin-right: 16px;

}

.single-select-item:hover {
    background-color: #f0f0f0;
}

.single-select-item.selected {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
}

.timetable-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.__footer-button {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 20px;

    button {
        min-width: 120px;
    }
}

.subject {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 50px;
    /* Adjust this value to set the text width */
    // word-break: auto-phrase;
    //white-space: nowrap;
    white-space: pre-line;
    // width: 90px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.single-select-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;

    /* Adjust this value to set the text width */
    @media (max-width: 550px) {
        max-width: 60px;
    }
}

fieldset {
    border: 0;
    padding: 0;
}


.__custom-timepicker {
    width: 100%;

    .MuiInputBase-root {
        height: 40px;
        /* Set the desired height */

    }
}

.__days_list_session {
    text-align: left;
    padding: 10px 20px;
}

.__session__ {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;
}

.__time_pickers___ {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
}

.__session_head {
    font-size: 23px;
    font-weight: 500;
    margin: 20px 0;

}

.__borderChange {
    padding: 0px 30px !important;
    height: 46px;
    color: #222 !important;
    cursor: pointer !important;
    border-radius: 23px !important;
    border: 1px solid #ccc;
    margin-left: 20px !important;

    span {
        color: #222 !important;
    }
}

.__two_btns {
    display: flex;
    gap: 10px;
    justify-content: flex-start !important;
}

.__time {
    max-width: 70px;
}

.error{
    color: red;
    margin-top: 5px;
}