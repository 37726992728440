@import '../../assets/styles/globalColors.scss';

.attendence_container{
.header{
    gap: 15px;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    margin: 0px 15px;
    .select{
        // min-width: 40vw;
        max-width: 160px !important;
        height: 55px;
        flex: 1 1;
        border-radius: 10px;
    }
}
.student_heading{
    background-color: $secondary-background;
    margin: 15px 0;
    padding: 10px 15px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: $secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading_checkbox_wrapper{
        display: flex;
    }
}
.students_wrapper{
    margin: 0px 15px;
    .student{
        padding: 8px 0px;
        border-bottom: 1px solid $secondary-background;
        display: flex;
        align-items: center;
        .student_text{
            margin-left: 10px;
            font-weight: 600;
            flex: auto;
            text-align: left;
            color: $secondary-color;
        }
    }
    .checkbox_wrapper{
        display: flex;
        .checkbox_content{
            margin-right: 16px;
            .checkbox{
            }
        }
        .checkbox_content_abscent{
            margin-right: 26px;
        }
    }
}
.footer{
    display: flex;
    justify-content: flex-end;
    margin: 15px;
}
}